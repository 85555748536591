<template>
  <div :style="{gap: '50px', display: 'flex', flexDirection: 'column'}">
    <div v-if="!isLogin" :style="{margin: '10px 0'}">
    <div
      @click="linkTo({name: 'auth.sign-in'})"
      :style="{cursor: 'pointer', border: '1px #032D98 solid', background: '#032D98 !important', width: '100%', height: '50px', borderRadius: '4px', display: 'flex', justifyContent: 'center', alignItems: 'center'}">
      <span :style="{color:'#fff', fontWeight: 700, fontSize: '16px'}">
        로그인
      </span>
    </div>
  </div>
    <div v-else :style="{gap: '10px', display: 'flex', flexDirection: 'column'}">
    <div
      @click="linkTo({name: 'user.profile'})"
      :style="{cursor: 'pointer', border: '1px #032D98 solid', background: '#032D98 !important', width: '100%', height: '50px', borderRadius: '4px', display: 'flex', justifyContent: 'center', alignItems: 'center'}">
      <span :style="{color:'#fff', fontWeight: 700, fontSize: '16px'}">
        마이 페이지
      </span>
    </div>
    <div
      @click="linkTo({name: 'dashboard.post'})"
      :style="{cursor: 'pointer', border: '1px #032D98 solid', background: '#fff !important', width: '100%', height: '50px', borderRadius: '4px', display: 'flex', justifyContent: 'center', alignItems: 'center'}">
      <span :style="{color:'#032D98', fontWeight: 700, fontSize: '16px'}">
        게시판 글쓰기
      </span>
    </div>
  </div>
    <!-- <div>
      <span :style="{ fontSize: '16px', fontWeight: 700, color: '#000' }"> 내가 작성한 글 </span>
      <div :style="{ paddingLeft: '10px', margin: '10px 0' }">
        <div :style="{ borderLeft: '2px #F2F2F2 solid', paddingLeft: '10px' }">
          <div :style="{ gap: '10px', width: '100%', display: 'flex', flexDirection: 'column' }">
            <span @click="linkTo({name: ''})" class="text-truncate" :style="{
              display: 'block',
              cursor: 'pointer',
              fontSize: '14px',
              fontWeight: setTextWeight(list),
              color: setTextColor(list)
            }">

            </span>
          </div>
        </div>
      </div>
    </div> -->
    <div :style="{gap: '20px', display: 'flex', flexDirection: 'column'}">
    <div v-for="(item, index) in categoryState" :key="item.category_id">
      <div :style="{display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer'}" @click="open(index)">
      <span :style="{ fontSize: '16px', fontWeight: 700, color: '#000' }">{{ item.category_name }}</span>
      <div>
        <i v-if="item.open" class="ri-arrow-up-s-line" :style="{ fontSize: '16px', fontWeight: 700, color: '#000' }"></i>
        <i v-else class="ri-arrow-down-s-line" :style="{ fontSize: '16px', fontWeight: 700, color: '#000' }"></i>
      </div>
      </div>
      <div v-show="item.open" :style="{ paddingLeft: '10px', margin: '10px 0' }">
        <div :style="{ borderLeft: '2px #F2F2F2 solid', paddingLeft: '10px' }">
          <div v-if="item.subcategory.length === 0">
            <span :style="{ fontSize: '14px', fontWeight: 400, color: '#000' }"> 카테고리가 없습니다. </span>
          </div>
          <div v-else :style="{ width: '100%', display: 'flex', flexDirection: 'column' }">
            <span v-for="list in item.subcategory" :key="list.category_id" @click="goPath(item.category_name, list.category_name)"
              class="text-truncate" :style="{
                display: 'block',
                cursor: 'pointer',
                fontSize: '14px',
                fontWeight: setTextWeight(list),
                color: setTextColor(list)
              }">
              {{ list.category_name }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>
<script>

import { core } from '../../../config/pluginInit'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'MySideMenuChildren1',
  props: {},
  components: {},
  data () {
    return {
      inCategory: this.$route.params.category,
      isLogin: false
    }
  },
  mounted () {
    const loggedIn = localStorage.getItem('hypercert_access_token')
    if (loggedIn !== undefined && loggedIn !== null) {
      this.isLogin = true
    }
  },
  computed: {
    ...mapGetters({
      categoryState: 'Category/categoryState'
    })
  },
  methods: {
    ...mapActions({
      categoryOpen: 'Category/open'
    }),
    setTextColor (item) {
      // if (this.$route.name === 'dashboard.categoryList') {
      if (this.$route.params.category === item.category_name) {
        return '#032D98'
      } else {
        return '#000'
      }
      // } else {
      //   if (this.$route.name === item) {
      //     return '#032D98'
      //   } else {
      //     return '#000'
      //   }
      // }
    },
    setTextWeight (item) {
      // if (this.$route.name === 'dashboard.categoryList') {
      if (this.$route.params.category === item.category_name) {
        return 700
      } else {
        return 400
      }
      // } else {
      //   if (this.$route.name === item) {
      //     return 700
      //   } else {
      //     return 400
      //   }
      // }
    },
    goPath (item, to) {
      this.$router.push({ name: 'dashboard.categoryList', params: { item: item, category: to } })
    },
    linkTo (to) {
      this.$router.push(to)
    },
    open (index) {
      this.categoryOpen(index)
    },
    activeLink (item) {
      return core.getActiveLink(item, this.$route.name)
    }
  },
  watch: {
    $route (to, from) {
      if (this.$route.name === 'dashboard.categoryList' || this.$route.name === 'dashboard.detail') {
        core.index()
      }
    }
  }
}
</script>
<style>
.my-hr-top {
  border: 0;
  height: 3px;
  background: rgb(134, 130, 130);
}

.my-hr-bottom {
  border: 0;
  height: 2px;
  background: rgb(134, 130, 130);
}
</style>
