<template>
        <carousel
        :loop="true"
        :items="1"
        :nav="true"
        :autoplay="true"
        :autoplayTimeout="5000"
        :dots="true"
        :navText="['','']"
        >
            <div
              v-for="( item, index ) in items" :key="index"
              class="banner" :style="{ backgroundImage: 'url(' + item.image + ')', backgroundSize: 'cover' }">
              <!-- class="banner" :style="{ backgroundImage: 'url(' + item.image + ')', backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }"> -->
                <span class="title" :style="{color: '#fff', display: 'block', position: 'absolute', left:'20%' }">
                    {{ item.title }}<br/>
                    <span class="sub">
                      {{ item.sub }}
                    </span>
                </span>
            </div>
        </carousel>
</template>
<script>

import carousel from 'vue-owl-carousel'

export default {
  name: 'Banner',
  components: {
    carousel
  },
  data () {
    return {
      items: [
        {
          image: require('../../assets/banner1.png'),
          title: 'TIPS',
          sub: 'Bulletin Reward Platform',
          button: () => { alert('aa') }
        },
        {
          image: require('../../assets/banner2.png'),
          title: '',
          sub: '',
          button: () => { alert('aa1') }
        },
        {
          image: require('../../assets/banner3.png'),
          title: '',
          sub: '',
          button: () => { alert('aa1') }
        }
      ]
    }
  },
  mounted () {
  },
  methods: {},
  computed: {},
  watch: {}
}
</script>
<style>
.owl-carousel .owl-nav .owl-next {
  transform: translate(0%, -80%) !important;
  right: 10% !important;
  background-image: url('../../assets/arrow_right.png') !important;
  background-color: transparent !important;
  height: 30px !important;
  width: 30px !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
}
.owl-carousel .owl-nav .owl-prev {
  transform: translate(0%, -80%) !important;
  left: 10% !important;
  background-image: url('../../assets/arrow_left.png') !important;
  background-color: transparent !important;
  height: 30px !important;
  width: 30px !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
}
.owl-carousel .owl-dots {
    position: absolute !important;
    bottom: 5%;
    text-indent: inherit;
    text-align: center;
    cursor: pointer;
}
.owl-carousel .owl-dots .owl-dot span {
    height: 7px !important;
    width: 8vw !important;
    background: #032D98 !important;
    border-radius: 0px !important;
}
.owl-carousel .owl-dots .owl-dot.active span {
    background: #FFFFFF !important;
    box-shadow: 0 0 0 0 #FFFFFF !important;
}
</style>
