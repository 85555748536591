<template>
  <div>
    <b-sidebar id="sidebar-1" title="" shadow>
      <div class="px-3 py-2">
        <nav class="iq-sidebar-menu" >
                        <MySideMenuChildren />
                    </nav>
      </div>
    </b-sidebar>
  </div>
</template>
<script>
// import { core, APPNAME } from '../../../../config/pluginInit'
import MySideMenuChildren from '../../menus/MySideMenuChildren1.vue'
import SideBarItems from '../../../../FackApi/json/SideBar.json'

export default {
  name: 'MySidebarStyle',
  props: {
    // logoClass: { type: String, default: 'w-20' }
  },
  components: {
    MySideMenuChildren
  },
  mounted () {
    // core.SmoothScrollbar()
  },
  methods: {
  },
  data () {
    return {
      // appName: APPNAME,
      items: [],
      SideBarItems: SideBarItems,
      open: false
    }
  }
  // methods: {
  //   sidebarMini () {
  //     core.triggerSet()
  //   }
  // }

}
</script>
<style>
.b-sidebar > .b-sidebar-body {
    flex-grow: 1;
    height: 100%;
    overflow: hidden;
}
</style>
