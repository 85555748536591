<template>
    <div :style="{ padding: 0, margin: 0, width: '100%', maxWidth: '100vw'}">
        <div
            :style="{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', background: '#fff' }">
            <div class="my-width"
                :style="{ display: 'flex', justifyContent: 'space-between !important', flexDirection: 'row', padding: '10px 0' }">
                <div :style="{ display: 'flex'} ">
                    <b-button v-show="showBanner" variant="outline-light" v-b-toggle.sidebar-1><i :style="{color: '#000 !important', fontWeight: 400, fontSize: '18px' }" class="ri-menu-line" /></b-button>
                    <div :style="{cursor: 'pointer', background: 'linear-gradient(116.6deg, #12319E 9.22%, #1E50FF 95.69%)', borderRadius: '4px', padding: '0 20px', display: 'flex', alignItems: 'center', justifyContent: 'center',}">
                <span @click="goPath('home.main')" :style="{ color: '#f5f5f5', fontWeight: 700, fontSize: '20px', display: 'flex', alignItems: 'center'}">
                    TIPS
                </span>
              </div>
            </div>
                <div :style="{ display: 'flex', alignItems: 'center', gap: '15px' }">
                  <span @click="goPath('home.main')" class="mobile-side" :style="{ cursor: 'pointer', color: '#000', fontWeight: 400, fontSize: '14px' }">
                        홈
                    </span>
                    <span @click="goPath('dashboard.latest')" class="mobile-side" :style="{ cursor: 'pointer', color: '#000', fontWeight: 400, fontSize: '14px' }">
                        게시판
                    </span>
                    <span @click="scrollTo('coin')" class="mobile-side" :style="{ cursor: 'pointer', color: '#000', fontWeight: 400, fontSize: '14px' }">
                        코인교환
                    </span>
                    <span @click="scrollTo('company')" class="mobile-side" :style="{ cursor: 'pointer', color: '#000', fontWeight: 400, fontSize: '14px'}">
                        회사소개
                    </span>
                </div>
                <div :style="{ display: 'flex', alignItems: 'center', justifyContent: 'end' }">
                  <!-- <div id='google_translate_element'></div> -->
                    <span @click="goPath('auth.sign-in')" v-if="!isLogin" :style="{ cursor: 'pointer', color: '#000', fontWeight: 400, fontSize: '14px', marginLeft: '50px' }">
                        로그인
                    </span>
                    <span @click="logout()" v-else :style="{ cursor: 'pointer', color: '#000', fontWeight: 400, fontSize: '14px', marginLeft: '50px' }">
                        로그아웃
                    </span>
                </div>
            </div>
          </div>
          <Banner />
    </div>
</template>
<script>

import { mapActions, mapGetters } from 'vuex'
import auth from '../../services/auth'
import Banner from '../../components/banner/Banner.vue'

export default {
  name: 'Header',
  components: {
    Banner
  },
  data () {
    return {
      appName: 'TIPS',
      my: '',
      isLogin: false,
      showBanner: true
    }
  },
  mounted () {
    const recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute(
      'src',
      'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
    )
    document.head.appendChild(recaptchaScript)
    this.my = localStorage.getItem('hypercert_access_token')
  },
  methods: {
    goMain () {
      if (this.$route.name !== 'home.main') {
        this.$router.push({ name: 'home.main' })
      }
    },
    goPath (path) {
      if (this.$route.name !== path) {
        if (path === 'dashboard.categoryList') {
          this.$router.push({ name: 'dashboard.categoryList', params: { category: 'latest' } })
          return
        }
        this.$router.push({ name: path })
      }
    },
    scrollTo (temp) {
      if (this.$route.name !== 'home.main') {
        this.$router.push({ name: 'home.main' })
      }
      try {
        const div = document.getElementById(temp)
        div.scrollIntoView({ behavior: 'smooth' }, false)
      } catch (err) {
        setTimeout(() => {
          const div = document.getElementById(temp)
          div.scrollIntoView({ behavior: 'smooth' }, false)
        }, 500)
      }
    },
    logout () {
      localStorage.removeItem('hypercert_access_token')
      this.doClear()
      alert('로그아웃 되었습니다.')
      window.location.href = `${window.location.protocol}//${window.location.host}/auth/sign-in`
      // this.$router.replace({ name: 'auth.sign-in' })
    },
    ...mapActions({
      updateUser: 'Auth/updateUserInfo',
      doClear: 'Auth/doClear'
    })
  },
  computed: {
    ...mapGetters({
      userState: 'Auth/userState',
      bookmark: 'Setting/bookmarkState'
      // langsOptions: 'Setting/langOptionState',
      // selectedLang: 'Setting/langState'
    })
  },
  watch: {
    my (newVal) {
      this.isLogin = newVal !== null
      if (this.isLogin && this.userState.name === '') {
        auth.myInfo().then(res => {
          const _user = res.data.user
          _user.referral = res.data.referral

          this.updateUser(_user)
        })
      }
    }
  }
}
</script>
<style type="text/css">
.fixed-con {
  z-index: 9724790009779558 !important;
  background-color: #f7f8fc;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
}

.VIpgJd-ZVi9od-aZ2wEe-wOHMyf {
  z-index: 9724790009779 !important;
  top: 0;
  left: unset;
  right: -5px;
  display: none !important;
  border-radius: 50%;
  border: 2px solid gold;
}

.VIpgJd-ZVi9od-aZ2wEe-OiiCO {
  width: 80px;
  height: 80px;
}

/*hide google translate link | logo | banner-frame */
.goog-logo-link,
.gskiptranslate,
.goog-te-gadget span,
.goog-te-banner-frame,
#goog-gt-tt,
.goog-te-balloon-frame,
div#goog-gt- {
  display: none !important;
}

.goog-te-gadget {
  color: transparent !important;
  font-size: 0px;
  max-width: 200px;
}
.goog-te-combo {
  width: 100%;
}

.goog-text-highlight {
  background: none !important;
  box-shadow: none !important;
}

/*google translate Dropdown */

#google_translate_element select {
  background: #f6edfd;
  color: #383ffa;
  border: none;
  border-radius: 3px;
  padding: 6px 8px
}

@media (max-width: 480px) {
  .goog-te-gadget {
  color: transparent !important;
  font-size: 0px;
  width: 100%;
}
.goog-te-combo {
  text-align: center;
}
}

</style>
